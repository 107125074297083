<template>
	<ValidationObserver ref="obs" tag="form">
		<v-card class="elevation-12" id="login-card" slot-scope="{ invalid }">
			<v-toolbar dark color="primary" flat>
				<v-toolbar-title>{{ $t("auth.loginUsingBankId") }}</v-toolbar-title>
			</v-toolbar>
			<v-card-text>
				<div class="my-2 d-flex flex-column align-center">
					<v-icon x-large class="mt-2 mb-4"> $vuetify.icons.bankid </v-icon>
					<div class="d-flex flex-column align-center" v-if="loading && interval">
						<canvas id="qr-code-container" ref="container"></canvas>
						<v-row
							class="my-2 text-center"
							v-if="loading"
							align-content="center"
							align="center"
							justify="center"
						>
							{{ status }}
						</v-row>
						<v-btn color="error" class="my-2" @click.prevent="cancel">Avbryt</v-btn>
					</div>

					<v-progress-circular
						v-else-if="loading && interval === null"
						:size="20"
						color="primary"
						class="mr-2"
						indeterminate
					></v-progress-circular>

					<div class="d-flex flex-column align-center w-100" id="login-options" v-else>
						<validation-provider
							:name="$t('auth.personalNumberLabel')"
							rules="required|digits:12"
						>
							<v-text-field
								solo
								clearable
								:hide-details="!invalid"
								:label="$t('auth.personalNumberInputLabel')"
								type="tel"
								v-model="personalNumber"
								slot-scope="{ errors }"
								:error-messages="errors"
								:disabled="loading"
								:loading="loading"
								class="mb-2"
							></v-text-field>
						</validation-provider>

            <validation-provider  v-if="externalidlist.length > 0" name="external" rules="required">
              <v-select
                prepend-icon="menu"
              :label="getMultiLoginText('label')"
                :name="getMultiLoginText('name')"
                :items="externalidlist"
                v-model="externalid"
                slot-scope="{ errors }"
                :error-messages="errors"
                :loading="loading"
                :disabled="loading"
              ></v-select>
          </validation-provider>

						<v-btn
							class="my-2 text-none"
							color="primary"
							type="submit"
							:loading="loading"
							:disabled="loading"
							@click.prevent="startTransaction(false)"
						>
							Mobilt BankID QR-kod
						</v-btn>
						<v-btn
							v-if="isMobile"
							class="my-2 text-none"
							color="primary"
							:loading="loading"
							:disabled="loading"
							@click.prevent="startTransaction(true)"
						>
							Mobilt BankID på denna enhet
						</v-btn>
					</div>
				</div>
			</v-card-text>
		</v-card>
	</ValidationObserver>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import QRCode from "qrcode";
import isMobileJs from "ismobilejs";
export default {

  props: {
    multipleLimeobjects: {
      default: () => {}
    }
  },

	data() {
		return {
			personalNumber: null,
      externalid: null,
      externalidlist: [],
			loading: false,
			interval: null,
			status: "",
			transactionId: null,
			isMobile: false,
			startOnSameDevice: false,
		};
	},

	components: {
		ValidationProvider,
		ValidationObserver,
	},

	methods: {
		generateQr(data) {
			this.$refs.container.innerHTML = "";
			QRCode.toCanvas(this.$refs.container, data, {
				errorCorrectionLevel: "M",
				width: 256
			});
		},

		launchApp(autoStartToken) {
			const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
			window.location.href = url;
		},

		cancel() {
			clearInterval(this.interval);
			this.loading = false;

			axios
				.post(this.route("login-bankid.cancel").toString(), {
					transaction_id: this.transactionId,
				})
				.then((res) => {
					console.log("Transaction cancelled", res);
				})
				.catch((err) => {
					console.error("error: ", err);
				});
		},

		async startTransaction(startOnDevice = false) {
			let valid = await this.$refs.obs.validate();

        	if (! valid) return;

			this.loading = true;
			this.startOnSameDevice = startOnDevice;

			axios
				.post(this.route("login-bankid.request").toString(), {
					personal_number: this.personalNumber,
          externalid: this.externalid,
				})
				.then((res) => {
					if (res.data.success === false) {
						this.$store.commit("snack/SET", {
							message: res.data.message,
							color: "error",
						});
						throw new Error();
					}
					window.onbeforeunload = () => "";

					this.status = "Starta BankID-appen";
					this.transactionId = res.data.transaction_id;

					if (this.isMobile && this.startOnSameDevice === true) {
						this.launchApp(res.data.auto_start_token);
					} else {
						this.$nextTick().then(() => {
							this.generateQr(res.data.qr_data);
						});
					}

					this.interval = setInterval(() => {
						this.pollTransaction();
					}, 3000);
				})
				.catch((err) => {
					console.error("err: ", err);
					this.loading = false;
					this.$store.commit("snack/SET", {
						message: _.get(
							err,
							"response.data.message",
							this.$t("common.somethingWentWrong")
						),
						color: "error",
					});
				});
		},

		reset(statusMessage) {
			clearInterval(this.interval);
			this.$refs.container.innerHTML = "";
			this.loading = false;
			this.status = statusMessage;
		},

		pollTransaction() {
			axios
				.post(this.route("login-bankid.collect").toString(), {
					transaction_id: this.transactionId,
          externalid: this.externalid
				})
				.then((res) => {
          console.log('res is', res.data);
					if (res.data.progressStatus === "COMPLETE") {
						this.reset(this.$t("auth.loginSuccess"));
						window.onbeforeunload = null;
						window.location.reload();
            return;
					} else if (res.data.progressStatus === "FAILED") {
						this.reset(res.data.message);
						this.$store.commit("snack/SET", {
							message: res.data.message,
							color: "error",
							timeout: 0,
						});
						return;
					}

					if (!this.startOnSameDevice) {
						this.generateQr(res.data.qr_data);
					}

					if (res.data.message) {
						this.status = res.data.message;
					}
				})
				.catch((err) => {
          const status = _.get(err, "response.status", this.$t("common.somethingWentWrong"));
          const TOO_MANY_REQUESTS = 429;
          const MULTIPLE_CHOICES = 300;
                  if (status === TOO_MANY_REQUESTS) {
           const username = _.head(_.keys(_.get(err, "response.data.errors")));
           message = _.head(_.get(err, `response.data.errors.${username}`));
           } else if (status === MULTIPLE_CHOICES) {
           this.externalidlist = err.response.data.data;
           return;
           } else {
           message = _.get(
               err,
               "response.data.message",
              this.$t("common.somethingWentWrong")
           );
           }


					this.$store.commit("snack/SET", {
						message: err.response.data.message,
						color: "error",
						timeout: 0,
					});

					clearInterval(this.interval);
					this.loading = false;
				});
		},

    getMultiLoginText(code) {
      return _.get(this.multipleLimeobjects, `${window.portal.locale}.${code}`, '');
    }
	},

	mounted() {
		this.isMobile = isMobileJs(navigator.userAgent).any;
	},
};
</script>
<style scoped>
#login-options > * {
	width: 100%;
	max-width: 100%;
}
.w-100 {
	width: 100%;
}
</style>