'use strict';

import 'babel-polyfill';

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import VeeValidate, { Validator } from 'vee-validate';
import VueI18n from 'vue-i18n';
import _ from 'lodash';

export default class {

  constructor(portalConfig) {
    this.portalConfig = portalConfig;
  }

  /**
   * Bootstrap.
   */
  exec() {
    this._bindLibrariesToWindow();
    this._setupVue(this.setupVueCallback);
    this._setupVeeValidate();
  }

  getI18nInstance() {
    const sv = require('./i18n/sv').default;
    const en = require('./i18n/en').default;
    const no = require('./i18n/no').default;
    const da = require('./i18n/da').default;
    const fi = require('./i18n/fi').default;
    const nl = require('./i18n/nl').default;
    const de = require('./i18n/de').default;

    return new VueI18n({
      locale: this.portalConfig.locale,
      fallbackLocale: 'en',
      messages: {
        sv,
        en,
        no,
        da,
        fi,
        nl,
        de
      }
    });
  }

  /**
   * Configure Vuetify.
   *
   * @param {object} extraOptions    Extra options to be merged.
   */
  getVuetifyInstance(extraOptions) {
      
    const sv = require('vuetify/es5/locale/sv').default;
    const en = require('vuetify/es5/locale/en').default;
    const no = require('vuetify/es5/locale/no').default;
    //const da = require('vuetify/es5/locale/da').default;
    // const fi = require('vuetify/es5/locale/fi').default;
    const nl = require('vuetify/es5/locale/nl').default;
    const de = require('vuetify/es5/locale/de').default;

    const vuetyfiSupportedLocales = ['sv', 'en', 'no', 'nl', 'de'];
    if (!vuetyfiSupportedLocales.includes(this.portalConfig.locale)) {
        console.warn('The selected locale is not supported by this Vuetify version. For the missing translations, see: https://github.com/vuetifyjs/vuetify/tree/master/packages/vuetify/src/locale');
    }

    let defaultOptions = {
      theme: {
        themes: {
          light: {
            primary: this.portalConfig.primary_color
          }
        }
      },
      lang: {
        locales: { sv, en, no, nl, de},
        current: this.portalConfig.locale
      }
    };

    Vue.use(Vuetify);

    return new Vuetify(
      _.merge(defaultOptions, extraOptions)
    );
  }

  /**
   * Set a callback function to provide extra Vue configuration.
   *
   * @param {function} callback
   */
  configureVue(callback) {
    this.setupVueCallback = callback;
  }

  /**
   * Bind some good-to-have libraries to the window object.
   */
  _bindLibrariesToWindow() {
    window.Vue = Vue;
    window._ = require('lodash');
    window.axios = require('axios');
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    window.axios.defaults.headers.common['X-User-Agent'] = `Lime Portal v${window.portal?.version || ''}`;
    let token = document.head.querySelector('meta[name="csrf-token"]');
    if (token) {
      window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
    } else {
      console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
    }

    // add an interceptor for 401 (Unauthorized) responses,
    // this probably means the session has expired and we
    // should redirect the user to the login page.
    window.axios.interceptors.response.use(res => res, err => {
      const UNAUTHORIZED = 401;
      const NOTFOUND     = 404; // can also be a disguised 403

      if (_.get(err, 'response.status') === UNAUTHORIZED) {
        window.location.reload();
      }

      if (_.get(err, 'response.status') === NOTFOUND) {
          const router = window.Vue.prototype['getRouter']?.();

          if (router && _.get(router, 'currentRoute.fullPath') !== '/404') {
            router.push(`/404`);
          }
      }

      return Promise.reject(err);
    });
  }

  /**
   * Configure VeeValidate.
   */
  _setupVeeValidate() {
    const sv = require('vee-validate/dist/locale/sv');
    const en = require('vee-validate/dist/locale/en');
    const no = require('vee-validate/dist/locale/nb_NO');
    const da = require('vee-validate/dist/locale/da');
    const fi = require('vee-validate/dist/locale/fi');
    const nl = require('vee-validate/dist/locale/nl');
    const de = require('vee-validate/dist/locale/de');

    Vue.use(VeeValidate, {
      locale: this.portalConfig.locale,
      inject: false,
      dictionary: { sv, en, no, da, fi, nl, de }
    });
  }

  /**
   * Configure Vue.
   *
   * @param {function|null} callback
   */
  _setupVue(callback = null) {

    // ziggy helper
    Vue.mixin({
      methods: {
        route: window.route
      }
    });

    if (callback && _.isFunction(callback)) {
      callback(Vue);
    }
  }

}
