export default {
    common: {
        loading: 'Indlæser...',
        somethingWentWrong: 'Noget gik galt!',
        close: 'Luk',
        submit: 'Send',
        cancel: 'Annullér',
        ok: 'OK',
        refresh: 'Opdater',
        yes: 'Ja',
        no: 'Nej',
        enter: 'Indtast',
        create: 'Opret'
    },
    limetype: {
        saveChanges: 'Gem ændringer',
        noChanges: 'Ingen ændringer foretaget',
        updateSuccess: 'Succes! Objektet er gemt',
        requestError: code => `Anmodning fejlet med status code: ${code}`,
        error: 'Noget gik galt, venligst præv igen og kontakt en administrator hvis fejlen fortsætter',
        filter: 'Filter',
        noData: 'Ingen data fundet'
    },
    auth: {
        login: 'Log ind',
        loginUsingBankId: 'Log ind med NemID/MitID',
        loginUsingCredentials: 'Log ind med brugernavn og adgangskode',
        loginWith: 'Log ind med',
        userDetails: 'Bruger detaljer',
        forgotPassword: 'Glemt din adgangskode?',
        forgotPasswordInfo: "Indtast din e-mail og vi vil sende dig et link til en hjemmeside for at nulstille din adgangskode",
        resetLinkSent: 'Vi har sendt dig en e-mail til at nulstille din adgangskode!',
        usernameLabel: 'Brugernavn',
        passwordLabel: 'Adgangskode',
        loginSuccess: 'Du er logget ind!',
        personalNumberInputLabel: 'DDMMYYNNNN',
        personalNumberLabel: 'Person nummer',
        emailLabel: 'E-mail',
        emailInputLabel: 'Indtast din e-mail',
        resetPassword: 'Nulstil adgangskode',
        passwordLabel: 'Adgangskode',
        newPasswordLabel: 'Ny adgangskode',
        newPasswordInputLabel: 'Indtast ny adgangskode',
        confirmPasswordLabel: 'Bekræft adgangskode',
        resetPasswordSuccess: 'Din adgangskode er nulstillet. Du vil nu blive viderestillet til login siden, hvor du kan logge på med den nye adgangskode.',
        "urn:grn:authn:no:bankid": 'Mobil eller Web',
        "urn:grn:authn:no:vipps": 'Vipps',
        "urn:grn:authn:no:bankid:central": "",
        "urn:grn:authn:no:bankid:mobile": "",
        "urn:grn:authn:dk:nemid:poces": "Personlig med nøglekort",
        "urn:grn:authn:dk:nemid:moces": "Medarbejder med nøglekort",
        "urn:grn:authn:dk:nemid:moces:codefile": "Medarbejder med kodefil",
        "urn:grn:authn:se:bankid:another-device": "BankID på en anden enhed",
        "urn:grn:authn:se:bankid:same-device": "BankID på denne enhed",
        authMethodInputLabel: 'Vælg en metode',
        authMethodLabel: 'Login metode',
        phoneNumberLabel: 'Mobil',
        phoneNumberInputLabel: 'XXXXXXXX',
        activateAccountInfo: 'Indtast din e-mail adresse og vi vil sende dig et link hvor du kan nulstille dit password.',
        activateAccount: 'Aktiver din konto',
        activationLinkSent: 'Aktiveringsmail sendt!',
        loginAgain: "Login igen",
        loggedOut: "Du er logget ud!"
    },
    nav: {
        loggedInAs: 'Logget ind som',
        logout: 'Log ud',
        start: 'Start',
        index: 'Liste',
        create: 'Opret'
    },
    startView: 'Start visning',
    startViewNotConfigured: 'No start view configured. See /readme.txt in the root folder for instructions.',
    pageNotFoundTitle: 'Whoops, 404',
    pageNotFoundText: 'Siden kunne ikke findes.'
}