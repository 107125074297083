var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ValidationObserver",
    { ref: "obs", attrs: { tag: "form" } },
    [
      _c(
        "v-card-text",
        [
          _c("validation-provider", {
            ref: "emailprovider",
            attrs: { name: _vm.$t("auth.usernameLabel"), rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "person",
                      label: _vm.$t("auth.usernameLabel"),
                      type: "text",
                      "error-messages": errors,
                      loading: _vm.loading,
                      disabled: _vm.loading,
                      placeholder: _vm.isAutoFilled ? " " : "",
                    },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("validation-provider", {
            ref: "pwprovider",
            attrs: { name: _vm.$t("auth.passwordLabel"), rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var errors = ref.errors
                  return _c("v-text-field", {
                    attrs: {
                      "prepend-icon": "lock",
                      name: "password",
                      label: _vm.$t("auth.passwordLabel"),
                      type: "password",
                      "error-messages": errors,
                      loading: _vm.loading,
                      disabled: _vm.loading,
                      placeholder: _vm.isAutoFilled ? " " : "",
                    },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _vm.authMethod === "custom_limetype"
            ? _c(
                "v-menu",
                {
                  attrs: { bottom: "", right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { icon: "" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3221905750
                  ),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    [
                      _c("v-list-item", [_c("activate-account")], 1),
                      _vm._v(" "),
                      _c("v-list-item", [_c("forgot-password")], 1),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                type: "submit",
                loading: _vm.loading,
                disabled: _vm.loading,
              },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.submit($event)
                },
              },
            },
            [_vm._v("\n\t\t\t" + _vm._s(_vm.$t("auth.login")) + "\n\t\t")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }