export default {
    common: {
        loading: 'Lädt...',
        somethingWentWrong: 'Da ist etwas schief gelaufen!',
        close: 'Schließen',
        submit: 'Senden',
        cancel: 'Abbrechen',
        ok: 'OK',
        refresh: 'Aktualisieren',
        yes: 'Ja',
        no: 'Nein',
        enter: 'Eingeben',
        create: 'Erstellen'
    },
    limetype: {
        saveChanges: 'Änderungen speichern',
        noChanges: 'Keine Änderungen vorgenommen',
        updateSuccess: 'Erfolgreich! Das Objekt wurde aktualisiert',
        requestError: code => `Anfrage fehlgeschlagen mit Statuscode: ${code}`,
        error: 'Etwas ist schief gelaufen. Bitte versuchen Sie es noch einmal und kontaktieren Sie einen Administrator, wenn das Problem weiterhin besteht.',
        filter: 'Filter',
        noData: 'Keine Daten gefunden'
    },
    auth: {
        login: 'Login',
        loginUsingBankId: 'Login mit BankID',
        loginUsingCredentials: 'Anmelden mit Zugangsdaten',
        loginWith: 'Anmelden mit',
        userDetails: 'Benutzer-Details',
        forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
        forgotPasswordInfo: "Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zu einer Seite, auf der Sie Ihr Passwort zurücksetzen können.",
        resetLinkSent: 'Wir haben Ihnen den Link zum Zurücksetzen des Passworts per E-Mail zugesandt!',
        usernameLabel: 'Benutzername',
        passwordLabel: 'Kennwort',
        loginSuccess: 'Sie sind jetzt eingeloggt!',
        personalNumberInputLabel: 'YYYYMMDDNNNN',
        personalNumberLabel: 'Persönliche Nummer',
        emailLabel: 'E-Mail',
        emailInputLabel: 'Geben Sie Ihre E-Mail ein',
        resetPassword: 'Passwort zurücksetzen',
        newPasswordLabel: 'Neues Passwort',
        newPasswordInputLabel: 'Wählen Sie ein neues Passwort',
        confirmPasswordLabel: 'Bestätigen Sie das neue Passwort',
        resetPasswordSuccess: 'Ihr Passwort wurde zurückgesetzt. Sie werden nun zur Anmeldeseite weitergeleitet, wo Sie sich mit Ihrem neuen Passwort anmelden können.',
        "urn:grn:authn:no:bankid": 'Mobiili vai Selain',
        "urn:grn:authn:no:vipps": 'Vipps',
        "urn:grn:authn:no:bankid:central": "NO BankID - Code card",
        "urn:grn:authn:no:bankid:mobile": "NO BankID - Mobile",
        "urn:grn:authn:dk:nemid:poces": "Henkilökohtainen, avainlukulista",
        "urn:grn:authn:dk:nemid:moces": "Työntekijä, avainlukulista",
        "urn:grn:authn:dk:nemid:moces:codefile": "Työntekijä, avainlukutiedosto",
        "urn:grn:authn:se:bankid:another-device": "BankID toisella laitteella",
        "urn:grn:authn:se:bankid:same-device": "BankID tällä laitteella",
        authMethodInputLabel: 'Wählen Sie eine Methode',
        authMethodLabel: 'Authentifizierungsmethode',
        phoneNumberLabel: 'Mobil',
        phoneNumberInputLabel: 'XXXXXXXX',
        activationLinkSent: 'Aktivierungsmail gesendet!',
        activateAccount: 'Aktivieren Sie Ihr Konto',
        activateAccountInfo: 'Geben Sie Ihre E-Mail-Adresse ein und wir senden Ihnen einen Link zu einer Seite, auf der Sie Ihr Passwort festlegen können.',
        loginAgain: "Erneut anmelden",
        loggedOut: "Sie sind abgemeldet!"
    },
    nav: {
        loggedInAs: 'Eingeloggt als',
        logout: 'Abmelden',
        start: 'Start',
        index: 'Liste',
        create: 'Erstellen'
    },
    startView: 'Ansicht starten',
    startViewNotConfigured: 'No start view configured. See /readme.txt in the root folder for instructions.',
    pageNotFoundTitle: 'Hoppla, 404',
    pageNotFoundText: 'Die Seite konnte nicht gefunden werden.'
}