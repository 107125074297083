<template>
	<ValidationObserver ref="obs" tag="form">
		<v-card-text>
			<validation-provider
				ref="emailprovider"
				:name="$t('auth.usernameLabel')"
				rules="required"
			>
				<v-text-field
					prepend-icon="person"
					:label="$t('auth.usernameLabel')"
					type="text"
					v-model="form.username"
					slot-scope="{ errors }"
					:error-messages="errors"
					:loading="loading"
					:disabled="loading"
					:placeholder="isAutoFilled ? ' ' : ''"
				></v-text-field>
			</validation-provider>

			<validation-provider
				ref="pwprovider"
				:name="$t('auth.passwordLabel')"
				rules="required"
			>
				<v-text-field
					prepend-icon="lock"
					name="password"
					:label="$t('auth.passwordLabel')"
					type="password"
					v-model="form.password"
					slot-scope="{ errors }"
					:error-messages="errors"
					:loading="loading"
					:disabled="loading"
					:placeholder="isAutoFilled ? ' ' : ''"
				></v-text-field>
			</validation-provider>
		</v-card-text>
		<v-card-actions>
			<v-menu bottom right v-if="authMethod === 'custom_limetype'">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on"
						><v-icon>mdi-dots-vertical</v-icon></v-btn
					>
				</template>
				<v-list>
					<v-list-item>
						<activate-account></activate-account>
					</v-list-item>
					<v-list-item>
						<forgot-password></forgot-password>
					</v-list-item>
				</v-list>
			</v-menu>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				type="submit"
				:loading="loading"
				:disabled="loading"
				@click.prevent="submit"
			>
				{{ $t("auth.login") }}
			</v-btn>
		</v-card-actions>
	</ValidationObserver>
</template>
<script>
import ForgotPassword from "./../../ForgotPassword.vue";
import ActivateAccount from "./../../ActivateAccount.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
	props: {
		authMethod: {
			required: true,
			type: String,
		},
	},

	components: {
		ValidationObserver,
		ValidationProvider,
		ForgotPassword,
		ActivateAccount,
	},

	data() {
		return {
			form: {
				username: "",
				password: "",
			},
			isAutoFilled: false,
			loading: false,
		};
	},

	methods: {
		async submit() {
			// dont use validationobserver for validation, since we have a nested observer in ForgotPassword.vue
			let valid = await Promise.all(
				this.validationproviders().map(async (p) => {
					let v = await p.validate();
					return v.valid;
				})
			);
			valid = _.every(valid);
			if (!valid) return;

			this.loading = true;

			let url =
				this.authMethod === "lime_user"
					? this.route("login-lime-user.request").toString()
					: this.route("login-custom-limetype.request").toString();

			axios
				.post(url, this.form)
				.then((res) => {
					if (_.get(res, "data.success") === true) {
						window.location.reload();
					}
				})
				.catch((err) => {
					let message = _.get(
							err,
							"response.data.message",
							this.$t("common.somethingWentWrong")
						);
					
					this.form.password = "";

					this.$store.commit("snack/SET", {
						message,
						color: "error",
						timeout: 0,
					});
				})
				.finally(() => {
					this.loading = false;
				});
		},

		validationproviders() {
			return [this.$refs.emailprovider, this.$refs.pwprovider];
		},
	},

	beforeCreate() {
    /** To prevent autofilled styling caused by browser,
     * we will apply an animation to those elements.
     * Once we know the animation has fired, we can adjust the elements to remove the styling.
     */
		this.$nextTick(() => {
			let ival = setInterval(() => {
				let els = this.$el.querySelectorAll("input");
				if (els.length) {
					clearInterval(ival);
					els.forEach((el) => {
						el.addEventListener("transitionstart", (e) => {
							if (e.propertyName === "background-color") {
								this.isAutoFilled = true;
							}
						});
						el.addEventListener("animationstart", (e) => {
							if (e.animationName === "onAutoFillStart") {
								this.isAutoFilled = true;
							}
						});
					});
				}
			}, 50);
		});
	},
};
</script>
<style>
@keyframes onAutoFillStart {
	from {
		/**/
	}
	to {
		/**/
	}
}
#login-card input:-webkit-autofill,
#login-card input:-internal-autofill-selected {
	animation-name: onAutoFillStart;
	background-color: inherit;
	transition: background-color 50000s ease-in-out 0s;
}
</style>