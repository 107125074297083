var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "obs",
    attrs: { tag: "form" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var invalid = ref.invalid
          return _c(
            "v-card",
            { staticClass: "elevation-12", attrs: { id: "login-card" } },
            [
              _c(
                "v-toolbar",
                { attrs: { dark: "", color: "primary", flat: "" } },
                [
                  _c("v-toolbar-title", [
                    _vm._v(_vm._s(_vm.$t("auth.loginUsingBankId"))),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "my-2 d-flex flex-column align-center" },
                  [
                    _c(
                      "v-icon",
                      { staticClass: "mt-2 mb-4", attrs: { "x-large": "" } },
                      [_vm._v(" $vuetify.icons.bankid ")]
                    ),
                    _vm._v(" "),
                    _vm.loading && _vm.interval
                      ? _c(
                          "div",
                          { staticClass: "d-flex flex-column align-center" },
                          [
                            _c("canvas", {
                              ref: "container",
                              attrs: { id: "qr-code-container" },
                            }),
                            _vm._v(" "),
                            _vm.loading
                              ? _c(
                                  "v-row",
                                  {
                                    staticClass: "my-2 text-center",
                                    attrs: {
                                      "align-content": "center",
                                      align: "center",
                                      justify: "center",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.status) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "my-2",
                                attrs: { color: "error" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.cancel($event)
                                  },
                                },
                              },
                              [_vm._v("Avbryt")]
                            ),
                          ],
                          1
                        )
                      : _vm.loading && _vm.interval === null
                      ? _c("v-progress-circular", {
                          staticClass: "mr-2",
                          attrs: {
                            size: 20,
                            color: "primary",
                            indeterminate: "",
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticClass:
                              "d-flex flex-column align-center w-100",
                            attrs: { id: "login-options" },
                          },
                          [
                            _c("validation-provider", {
                              attrs: {
                                name: _vm.$t("auth.personalNumberLabel"),
                                rules: "required|digits:12",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return _c("v-text-field", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          solo: "",
                                          clearable: "",
                                          "hide-details": !invalid,
                                          label: _vm.$t(
                                            "auth.personalNumberInputLabel"
                                          ),
                                          type: "tel",
                                          "error-messages": errors,
                                          disabled: _vm.loading,
                                          loading: _vm.loading,
                                        },
                                        model: {
                                          value: _vm.personalNumber,
                                          callback: function ($$v) {
                                            _vm.personalNumber = $$v
                                          },
                                          expression: "personalNumber",
                                        },
                                      })
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _vm.externalidlist.length > 0
                              ? _c("validation-provider", {
                                  attrs: {
                                    name: "external",
                                    rules: "required",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return _c("v-select", {
                                            attrs: {
                                              "prepend-icon": "menu",
                                              label:
                                                _vm.getMultiLoginText("label"),
                                              name: _vm.getMultiLoginText(
                                                "name"
                                              ),
                                              items: _vm.externalidlist,
                                              "error-messages": errors,
                                              loading: _vm.loading,
                                              disabled: _vm.loading,
                                            },
                                            model: {
                                              value: _vm.externalid,
                                              callback: function ($$v) {
                                                _vm.externalid = $$v
                                              },
                                              expression: "externalid",
                                            },
                                          })
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "my-2 text-none",
                                attrs: {
                                  color: "primary",
                                  type: "submit",
                                  loading: _vm.loading,
                                  disabled: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.startTransaction(false)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tMobilt BankID QR-kod\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.isMobile
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "my-2 text-none",
                                    attrs: {
                                      color: "primary",
                                      loading: _vm.loading,
                                      disabled: _vm.loading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.startTransaction(true)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tMobilt BankID på denna enhet\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }