<template>
	<ValidationObserver ref="obs" tag="form">
		<v-card-text>
			<validation-provider
				:name="$t('auth.personalNumberLabel')"
				rules="required|digits:12"
			>
				<v-text-field
					solo
					clearable
					:label="$t('auth.personalNumberInputLabel')"
					type="tel"
					hide-details="auto"
					v-model="form.personalNumber"
					slot-scope="{ errors }"
					:error-messages="errors"
					:disabled="loading"
					:loading="loading"
				></v-text-field>
			</validation-provider>
			<v-row
				class="mt-4"
				v-if="loading"
				align-content="center"
				align="center"
				justify="center"
			>
				<v-progress-circular
					:size="20"
					color="primary"
					class="mr-2"
					indeterminate
				></v-progress-circular>
				{{ status }}
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				type="submit"
				:loading="loading"
				:disabled="loading"
				@click.prevent="submit"
			>
				{{ $t("auth.login") }}
			</v-btn>
		</v-card-actions>
	</ValidationObserver>
</template>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
	components: {
		ValidationProvider,
		ValidationObserver,
	},

	data() {
		return {
			form: {
				personalNumber: "",
			},

			interval: null,
			loading: false,
			status: this.$t("common.loading"),
		};
	},

	methods: {
		async submit() {
			let valid = await this.$refs.obs.validate();

			if (!valid) return;

			this.loading = true;

			axios
				.post(this.route("login-bankid.request").toString(), {
					personal_number: this.form.personalNumber,
				})
				.then((res) => {
					if (res.data.success === false) {
						this.$store.commit("snack/SET", {
							message: res.data.message,
							color: "error",
						});
						throw new Error();
					}

					window.onbeforeunload = () => "";

					this.interval = setInterval(() => {
						this.pollTransaction(res.data.transaction_id);
					}, 3000);
				})
				.catch((err) => {
					this.loading = false;
					this.$store.commit("snack/SET", {
						message: _.get(
							err,
							"response.data.message",
							this.$t("common.somethingWentWrong")
						),
						color: "error",
					});
				});
		},

		pollTransaction(transactionId) {
			axios
				.post(this.route("login-bankid.collect").toString(), {
					transaction_id: transactionId,
				})
				.then((res) => {
					if (res.data.progressStatus === "COMPLETE") {
						clearInterval(this.interval);
						this.status = this.$t("auth.loginSuccess");
						window.onbeforeunload = null;
						window.location.reload();
					}

					if (res.data.message) {
						this.status = res.data.message;
					}
				})
				.catch((err) => {
					this.$store.commit("snack/SET", {
						message: err.response.data.message,
						color: "error",
						timeout: 0,
					});

					clearInterval(this.interval);
					this.loading = false;
				});
		},
	},
};
</script>